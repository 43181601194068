import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'
import VersionList from '../../components/VersionsList'

class landoopCSD extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')

    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />
        <section className="my-5 py-5 bg-gray">
          <h1 className="text-center my-4">
            Third Party Software - Landoop CSD
          </h1>
        </section>

        <section className="my-5 py-5">
          <div className="container-1">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <b>Please choose the version of lenses: </b>

                <VersionList />

                <h4 className="underline">
                  Third Party Software : Landoop CSD
                </h4>

                <p>
                  The table below lists third party software that is
                  incorporated into Landoop FAST DATA CSD tools, along with the
                  license associated with the software.
                </p>

                <table className="w-100 table-striped table-hover">
                  <thead className="thead">
                    <tr>
                      <th
                        className="text-center w-40"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        Project
                      </th>
                      <th
                        className="text-center w-60"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        License
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">ace-builds</td>
                      <td className="text-center">
                        <a
                          href="https://github.com/ajaxorg/ace-builds"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ace-diff</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/benkeen/ace-diff"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://angularjs.org/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-base64</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ninjatronic/angular-base64/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-diff-match-patch</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/amweiss/angular-diff-match-patch"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-google-chart</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-google-chart/angular-google-chart"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-json-tree</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/awendland/angular-json-tree"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Creative Commons{' '}
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-material</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://material.angularjs.org/latest/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        angular-material-data-table
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/daniel-nagy/md-data-table"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-oboe</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/RonB/angular-oboe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-spinner</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/urish/angular-spinner"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-ui-ace</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-ui/ui-ace"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-ui-grid</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://ui-grid.info/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angularUtils-pagination</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/michaelbromley/angularUtils-pagination"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">bootstrap</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://getbootstrap.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">font-awesome</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://fontawesome.io/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          OFL 1.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">google-fonts</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://fonts.google.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          OFL 1.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jszip</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://stuk.github.io/jszip/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jszip-utils</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Stuk/jszip-utils"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">oboe</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://oboejs.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD 2
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">spin.js</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/fgnass/spin.js/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p className="my-4">
                  The table below lists third party software that is
                  incorporated into Landoop CSDs, along with the license
                  associated with the software.
                </p>

                <table className="w-100 table-striped table-hover">
                  <thead className="thead">
                    <tr>
                      <th
                        className="text-center w-40"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        Project
                      </th>
                      <th
                        className="text-center w-60"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        License
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">Caddy</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://caddyserver.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>{' '}
                    <tr>
                      <td className="text-center">Grafana</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/grafana/grafana"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Prometheus</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://prometheus.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Prometheus alert manager</td>
                      <td className="text-center">
                        <a
                          href="https://github.com/prometheus/alertmanager"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Prometheus JMX exporter</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/prometheus/jmx_exporter"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Prometheus Node exporter</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/prometheus/node_exporter"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Stream Reactor</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/datamountaineer/stream-reactor"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        kafka-http-metrics-reporter
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/arnobroekhof/kafka-http-metrics-reporter"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Kafka brokers</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Kafka Rest</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/kafka-rest"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Schema Registry</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/schema-registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        Kafka Elasticsearch connector
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/kafka-connect-elasticsearch"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Kafka HDFS connector</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/kafka-connect-hdfs"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">Kafka JDBC connector</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/kafka-connect-jdbc"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        Confluent Inc Metrics Client
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/support-metrics-client"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default landoopCSD

export const pageQuery = graphql`
  query ThirdPartySofwarelandoopCSDSEO {
    allContentfulPages(
      filter: {
        pageReference: {
          pageIdentifier: { eq: "Third_Party_Software_Landoop_CSD" }
        }
      }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
